import * as ScrollMagic from "scrollmagic"; // Or use scrollmagic-with-ssr to avoid server rendering problems
import { TweenMax } from "gsap"; // Also works with TweenLite and TimelineLite
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
ScrollMagicPluginGsap(ScrollMagic, TweenMax);

topAnimation();

function topAnimation() {
    // TOP アニメーション
    var controller1 = new ScrollMagic.Controller();

    var aniNews = new TimelineMax();
    if(navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/i)){ // SP
      aniNews.from('.top-news__list', 0.8, {
        opacity: 0,
        transform: "translateY(40px)",
      })
    } else { // PC
      aniNews.from('.top-news__list', 1, {
        opacity: 0,
        transform: "translateY(50px)",
      })
    }


    var aniCommit = new TimelineMax();
    if(navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/i)){ // SP
      aniCommit.from('#commitFade', 0.8, {
        opacity: 0,
        transform: "translateY(40px)",
      })
    } else { // PC
      aniCommit.from('#commitFade', 1, {
        opacity: 0,
        transform: "translateY(50px)",
      })
    }


    var aniMenu = new TimelineMax();
    if(navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/i)){ // SP
      aniMenu.staggerFrom('.top-menu-list__item', 2, {
        opacity: 0,
        transform: "translateY(50px)",
      }, 0.6, '-=0.2');
      aniMenu.staggerFrom('.top-menu-area__item', 1, {
        opacity: 0,
        transform: "translateY(40px)",
      }, 0.2, '-=0.5');
    } else { // PC
      aniMenu.staggerFrom('.top-menu-list__item', 1.3, {
        opacity: 0,
        transform: "translateY(60px)",
      }, 0.6, '-=0.2');
      aniMenu.staggerFrom('.top-menu-area__item', 0.5, {
        opacity: 0,
        transform: "translateY(50px)",
      }, 0.2, '-=0.5');
    }


    var aniAbout = new TimelineMax();
    if(navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/i)){ // SP
      aniAbout.from('#aboutFade', 0.8, {
        opacity: 0,
        transform: "translateY(40px)",
      })
    } else { // PC
      aniAbout.from('#aboutFade', 1, {
        opacity: 0,
        transform: "translateY(50px)",
      })
    }



    var scene1 = new ScrollMagic.Scene({
        triggerElement: ".top-news__list",
        triggerHook: 1.2,
        reverse: false
    })
    .setTween(aniNews)
    .addTo(controller1);

    var scene2 = new ScrollMagic.Scene({
        triggerElement: "#commitFade",
        triggerHook: 1.2,
        reverse: false
    })
    .setTween(aniCommit)
    .addTo(controller1);

    var scene3 = new ScrollMagic.Scene({
        triggerElement: ".top-menu-list",
        triggerHook: 1.2,
        reverse: false
    })
    .setTween(aniMenu)
    .addTo(controller1);

    var scene4 = new ScrollMagic.Scene({
        triggerElement: "#aboutFade",
        triggerHook: 1.2,
        reverse: false
    })
    .setTween(aniAbout)
    .addTo(controller1);

}